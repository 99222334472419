.preview-containter{
    display: grid;
    grid-template-columns: 1fr 2fr 1fr;
    position: relative;
    border-top: 1px solid var(--shadow-gray);
    border-bottom: 1px solid var(--alert-red);
    padding: 2rem 0;
}
.container-video{
    display: grid;
    grid-template-columns: 1fr 2fr 1fr;
    position: relative;
    border-top: 1px solid var(--shadow-gray);
    border-bottom: 1px solid var(--alert-red);
    padding: 2rem 0;
}
.container-video::after{
    content: '';
    position: absolute;
    width: 50%;
    left:25%;
    height: 2px;
    bottom: 0;
    background-color: var(--alert-red);
}

.embebed-video {
    position: relative;
    padding-bottom: 56.25%;
    height: 0px;
    overflow: hidden;
}
.embebed-video iframe{
    /*
    aspect-ratio: 16 / 9;
    width: 100%;
    height: 100%; 
    */
    position: absolute;
    top:0;
    left: 0;
    width: 100%;
    height: 100%; 
}

.center{
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 2rem;
}
.center h2{
    font-size: 2.25rem;
    color: var(--black);
    text-transform: uppercase;
}

.center p{
    font-size: 2.5rem;
    color: var(--alert-red);
}

@media (max-width: 1150px){
    .center h2{
        font-size: 1.6rem;
    }
}

@media (max-width: 800px) {
    .container-video{
        grid-template-columns: 1fr;
        padding-top: 0;
        padding-bottom: .5rem;
    }
    
    .container-video::after{
        display: none;
    }

    .center {
        padding:0rem 1rem;
    }
    .center p{
        margin: 2px 0px;
        font-size: 2.2rem;
    }

    .center h2{
        font-size: 1.7rem;
    }
}

@media (max-width: 500px){
    .center h2{
        font-size: 1.2rem;
        margin-top: 6px;
    }
}